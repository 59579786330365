<template>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <button style="margin: 10px" @click="mintHouse(nftAddress, nftID)">
            mintHouse
        </button>
        <button style="margin: 10px" @click="updateBalance()">
            update hounted house balance
        </button>
        <div style="color: black"> My haunted house balance: {{myBalance}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">Oni Coin Contract Address </label>
            <input type="text" v-model="oniCoinContractAddress">
        </div>
        <button style="margin: 10px" @click="setOniCoinAddress(oniCoinContractAddress)">
            Set Oni Coin Address
        </button>
        <button style="margin: 10px" @click="getOniCoinAddress()">
            Get NFT Oni Address
        </button>
        <br>
        <div style="color: black"> Oni Coin Address: {{oniCoinAddress}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> House Game Contract Address </label>
            <input type="text" v-model="houseGameContractAddress">
        </div>
        <button style="margin: 10px" @click="setHouseGameAddress(houseGameContractAddress)">
            Set Oni Coin Address
        </button>
        <button style="margin: 10px" @click="getHouseGameAddress()">
            Get NFT Oni Address
        </button>
        <br>
        <div style="color: black"> House Game Address: {{houseGameAddress}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <button style="margin: 10px" @click="pause()">
            Pause
        </button>
        <button style="margin: 10px" @click="unpause()">
            Unpause
        </button>
        <button style="margin: 10px" @click="enableMinting()">
            Enable Minting
        </button>
    </div>
    
</template>
<script src="./TrialMintHouse.js"></script>
