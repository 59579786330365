/* eslint-disable no-unused-vars */
import axios from 'axios';
import { Web3GameService } from '../../services/Web3GameService';
export default {
  name: 'TrialMintHouse',
  props: {
    msg: String,
  },
  data: () => ({
    game: null,
    nftID: '',
    nftAddress: '',
    walletToName: {
      '0xFF43eD599B3c80fB71bb67c9D5452765e63d7a43': 'Bruce',
      '0xad7595fb1b3dF0468522106312F9c968152059A7': 'Nicholas',
      '0xf5C9d16C74269DcD8281C91490fC69564372a55F': 'Jonathan',
      '0x3D940e2Ee4430C1fF1c8D345A70ba82ED3823E48': 'Eduardo',
    },
    web3Config: new Web3GameService(),
    account: null,
    username: '',
    oniCoinAddress: '',
    houseGameAddress: '',
    myBalance: ''
  }),
  methods: {
    getWeb3Config() {
      this.web3Config
        .getConfig()
        .then((config) => {
          this.account = config.accounts[0];
          this.username = this.walletToName[this.account];
          this.accountsWatch();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    accountsWatch() {
      if (this.web3Config.provider) {
        this.web3Config.provider.on('accountsChanged', (accounts) => {
          this.account = accounts[0];
          this.web3Config.account = accounts[0];
        });
      }
    },
    async getOniCoinAddress() {
      let oniCoinAddress2 = await this.web3Config.getOniCoinAddressForhauntedHouse();
      this.oniCoinAddress = oniCoinAddress2;
    },
    async getHouseGameAddress() {
      let houseGameAddress = await this.web3Config.getHouseHameAddressForHauntedHouse();
      this.houseGameAddress = houseGameAddress;
    },
    async updateBalance() {
      let myBalance = await this.web3Config.getBalanceOfHauntedHouse();
      this.myBalance = myBalance;
    },
    async enableMinting() {
      await this.web3Config.enableHauntedHouseMinting();
    },
    async setOniCoinAddress(oniCoinAddress) {
      await this.web3Config.setOniCoinAddressForHauntedHouse(oniCoinAddress.toString());
    },
    async setHouseGameAddress(houseGameAddress) {
      await this.web3Config.setHouseGameAddressForHauntedHouse(houseGameAddress.toString());
    },
    async mintHouse(nftAddress, nftID) {
      await this.web3Config.mintHauntedHouse(nftAddress, nftID);
    },
    async unpause() {
      await this.web3Config.unpauseHauntedHouseContract();
    },
    async pause() {
      await this.web3Config.pauseHauntedHouseContract();
    },
  },
  mounted() {
    const { nftAddress, nftID } = this.$route.params;
    this.nftID = nftID;
    this.nftAddress = nftAddress;
    this.getWeb3Config();
  },
};
